import React, { useState, useEffect, useRef } from 'react';
import { FaShareAlt, FaFacebook, FaWhatsapp, FaTelegram, FaLinkedin, FaRobot } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const SocialShare = () => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const toggleShareOptions = () => {
    setIsOpen(!isOpen);
  };

  const openExternalLink = () => {
    alert("You are now opening an external link to ask anything on CircuitCure AI.");
    window.open("https://ai.circuitcure.com/", "_blank", "noopener,noreferrer");
  };

  // Handle clicks outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const currentUrl = `${window.location.href}`;

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}&quote=Try%20CircuitCure%20now%20and%20say%20goodbye%20to%20computer%20woes!%20#CircuitCure%20#ComputerRepair%20#TechnicianLocator`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=Try%20CircuitCure%20now%20and%20say%20goodbye%20to%20computer%20woes!%20#CircuitCure%20#ComputerRepair%20#TechnicianLocator`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}Try%20CircuitCure%20now%20and%20say%20goodbye%20to%20computer%20woes!%20#CircuitCure%20#ComputerRepair%20#TechnicianLocator%20`,
    telegram: `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}&text=Try%20CircuitCure%20now%20and%20say%20goodbye%20to%20computer%20woes!%20#CircuitCure%20#ComputerRepair%20#TechnicianLocator`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}&title=Try%20CircuitCure%20now%20and%20say%20goodbye%20to%20computer%20woes!%20#CircuitCure%20#ComputerRepair%20#TechnicianLocator`
  };

  return (
    <div className="social-share-container" ref={containerRef}>
      <div className="share-icon" onClick={toggleShareOptions}>
        <FaShareAlt />
      </div>
      {isOpen && (
        <div className="share-options">
          <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaFacebook />
          </a>
          <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaXTwitter />
          </a>
          <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaWhatsapp />
          </a>
          <a href={shareLinks.telegram} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaTelegram />
          </a>
          <a href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaLinkedin />
          </a>
        </div>
      )}
      <div className="robot" onClick={openExternalLink}>
        <div className="robot-icon">
          <FaRobot />
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
