import React, { useState, useEffect, useContext } from 'react';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { issues } from '../data/issues';
import { speakText } from '../utils/speech';
import DarkModeContext from '../DarkModeContext';
import { FaWhatsapp, FaGlobe, FaSearch } from 'react-icons/fa';

const filterResults = (results) => {
  return results.filter(result => {
    const relevantKeywords = ['anti-virus','virus','antivirus', 'computer', 'technology', 'software', 'hardware', 'refurbished', 'brand new', 'boxed', 'preowned', 'renewed', 'bios', 'repair', 'fixed', 'solved', 'stuck', 'restart', 'os', 'mac','windows 10', 'windows 11', 'windows 7', 'ubuntu', 'linux', 'operating system', 'screen', 'size', 'auto repair', 'pc', 'desktop', 'laptop', 'server', 'troubleshooting', 'drivers'];
    return relevantKeywords.some(keyword => result.title.includes(keyword) || result.snippet.includes(keyword));
  });
};

const fetchGoogleResults = async (query) => {
  const apiKey = 'AIzaSyCFbrH6CqJywAWkdY-B4ZUho6Ox8hIphmI';
  const cx = '40ca7d6edb3594643';
  const url = `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${cx}&q=${query}`;

  const response = await fetch(url);
  const data = await response.json();
  const filteredResults = filterResults(data.items || []);
  return filteredResults;
};

const debouncedSearch = debounce(async (query, setResults, fuse) => {
  if (query) {
    const result = fuse.search(query);
    if (result.length > 0) {
      setResults(result.map(r => r.item));
      speakText(`Found ${result.length} results for ${query}`);
    } else {
      const googleResults = await fetchGoogleResults(query);
      setResults(googleResults.map(item => ({
        id: item.link,
        title: item.title,
        description: item.snippet,
        isGoogleResult: true
      })));
      speakText(`Found ${googleResults.length} web results for ${query}`);
    }
  } else {
    setResults([]);
  }
}, 800);

function Home() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [nickname, setNickname] = useState('');
  const [isNicknameSet, setIsNicknameSet] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const { darkMode } = useContext(DarkModeContext);

  const fuse = new Fuse(issues, { keys: ['title', 'description'], includeScore: true });

  
  useEffect(() => {
    const storedNickname = localStorage.getItem('nickname');
    if (storedNickname) {
      setNickname(storedNickname);
      setIsNicknameSet(true);
    }
  }, []);

  useEffect(() => {
    const storedSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    setRecentSearches(storedSearches);
  }, []);

  useEffect(() => {
    if (isNicknameSet) {
      const fullText = `Hello ${nickname}! Welcome to CircuitCure, your solution to every computer issue.`;
      setDisplayedText('');
      let index = 0;
      const typingInterval = setInterval(() => {
        setDisplayedText(prev => {
          if (index >= fullText.length) {
            clearInterval(typingInterval);
            return fullText;
          }
          return prev + fullText[index];
        });
        index++;
      }, 100);
      return () => clearInterval(typingInterval);
    }
  }, [isNicknameSet, nickname]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setHasSearched(true);
    debouncedSearch(value, setResults, fuse);
  };

  const handleSearch = () => {
    if (query) {
      const updatedSearches = [query, ...recentSearches.filter(search => search !== query)].slice(0, 5);
      setRecentSearches(updatedSearches);
      localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
      setHasSearched(true);
      debouncedSearch(query, setResults, fuse);
    }
  };

  const handleNicknameSubmit = () => {
    localStorage.setItem('nickname', nickname);
    setIsNicknameSet(true);
  };

  const handleExternalLinkClick = (e, url) => {
    e.preventDefault();
    if (window.confirm("You are now opening an external link. Do you want to proceed?")) {
      window.open(url, '_blank');
    }
  };

  const clearRecentSearch = (search) => {
    const updatedSearches = recentSearches.filter(item => item !== search);
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  return (
    <div
      className={`relative flex flex-col items-center justify-center min-h-screen ${
        darkMode ? 'bg-neutral-900 text-neutral-100' : 'bg-neutral-100 text-neutral-900'
      } overflow-hidden transition-colors duration-500`}
    >
      <div className="absolute top-0 right-0 p-4 z-20"></div>


      {!isNicknameSet ? (
        <div className="z-10 text-center">
          <h1 className="text-2xl mb-4">What's your nickname?</h1>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className={`p-2 border rounded mb-4 w-full max-w-md ${ darkMode ? 'bg-neutral-800 text-neutral-200' : 'bg-neutral-200 text-neutral-800'}`}
            placeholder="Enter your nickname..."
          />
          <button
            onClick={handleNicknameSubmit}
            className={`${ darkMode ? 'bg-neutral-900 text-neutral-100' : 'bg-neutral-100 text-neutral-900'} px-4 py-2 rounded hover:bg-primary-600 transition-colors duration-300`}
          >
            Submit
          </button>
        </div>
      ) : (
        <div className="text-center z-10 w-full max-w-lg">
          <div className="typing-container mx-auto">
            <span className="typing-text">{displayedText}</span>
            <span className="typing-cursor">|</span>
          </div>
        </div>
      )}

<div className="relative w-full max-w-lg px-4 z-10 mt-6">
  <div className="relative flex items-center">
    <input
      type="text"
      value={query}
      onChange={handleInputChange}
      onClick={() => setQuery('')}
      className={`w-full p-4 pr-20 rounded-lg ${darkMode ? 'bg-neutral-800 text-neutral-200' : 'bg-white text-neutral-900'} focus:outline-none focus:ring-2 focus:ring-primary-500 transition-all duration-300`}
      placeholder="Describe your computer issue..."
    />

    <div className="absolute inset-y-0 right-0 flex items-center pr-4 space-x-2">
    <button
        className="flex items-center justify-center w-8 h-8 hover:text-primary-600 transition-all duration-300"
        onClick={handleSearch}
        title="Search"
      >
        <FaSearch size={20} />
      </button>
      <button
        className="flex items-center justify-center w-8 h-8 hover:text-primary-600 transition-all duration-300"
        onClick={async () => {
          if (query) {
            const googleResults = await fetchGoogleResults(query);
            setResults(
              googleResults.map((item) => ({
                id: item.link,
                title: item.title,
                description: item.snippet,
                isGoogleResult: true,
              }))
            );
            speakText(`Found ${googleResults.length} web results for ${query}`);
          }
        }}
        title="Search Google"
      >
        <FaGlobe size={20} />
      </button>

      
    </div>
  </div>

  {recentSearches.length > 0 && (
    <ul className="mt-2 list-none p-0">
      {recentSearches.slice(0, 5).map((search, index) => (
        <li
          key={index}
          className={`flex items-center justify-between mb-2 ${darkMode ? 'text-neutral-300' : 'text-neutral-700'}`}
        >
          <span
            onClick={() => setQuery(search)}
            className="cursor-pointer hover:underline"
          >
            {search}
          </span>
          <button
            onClick={() => clearRecentSearch(search)}
            className="text-red-500 hover:text-red-700"
          >
            X
          </button>
        </li>
      ))}
    </ul>
  )}
</div>


      <div className="w-full max-w-lg px-4 mt-6 z-10">
        {results.length > 0 && (
          <ul className="list-none p-0">
            {results.map((issue) => (
              <li
                key={issue.id}
                className={`mb-4 p-4 rounded-lg shadow-md transform transition-transform duration-500 hover:scale-105 ${
                  darkMode ? 'bg-neutral-800' : 'bg-white'
                }`}
              >
               
                {issue.isGoogleResult ? (
                  <a
                    href={issue.id}
                    onClick={(e) => handleExternalLinkClick(e, issue.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="text-xl font-semibold">{issue.title}</h2>
                    <p className="hover:underline">{issue.description}</p>
                    {issue.isGoogleResult && (
                  <p className="text-sm">Result from Web</p>
                )}
                  </a>
                ) : (
                  <>                    
                  <a
                    href={`/issue/${issue.id}`}
                    ><h2 className="text-xl font-semibold">{issue.title}</h2>                                       
                    <p>{issue.sub_description}</p>
                  </a>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
        {hasSearched && results.length === 0 && (
          <div className="text-center mt-6">
            <p>No results found.</p>
            <a href="https://wa.me/263783827570?text=Hello%20Ngaavongwe" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center mt-4 text-white bg-green-500 hover:bg-green-600 py-2 px-4 rounded-lg">
              <FaWhatsapp className="mr-2" />
              Contact Us on WhatsApp
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
